<template>
  <div id="print">
    <div id="main" style="position:fixed; top: 275px; left:90px; right: 90px;">
      <p style="font-family: Arial; font-size: 18px;">
        {{ cav.created_at | standardDate }}
      </p>
      <b style="font-family: Arial; font-size: 18px; font-weight:bold"
        >CAV (CHED-CARAGA) NO. {{ cav.control_no }}</b
      >
      <p style="font-family: Arial; font-size: 18px;" class="mb-4">
        Series of {{ new Date(cav.receipt_date).getFullYear() }}
      </p>

      <h5
        class="text-center mb-4"
        style="font-family: Arial; font-size: 28px; font-weight:bold;"
      >
        CERTIFICATION, AUTHENTICATION AND VERIFICATION
      </h5>
      <div style="font-family: Arial; font-size: 18px;">
        <p>To Whom it May Concern:</p>
        <p
          style="text-indent: 50px; text-align:justify; text-justify: inter-word;"
        >
          This is to certify that based on the confirmation forwarded by
          <span v-if="cav.type == 'LUC'">LUC</span> <span v-else>HEI</span>, the
          following information are true and correct:
        </p>
      </div>

      <!-- STUDENT INFO -->
      <div id="desc" style="font-family: Arial; font-size: 18px;" class="mt-3">
        <div id="labels">
          <p class="mb-0">
            Name of Student
          </p>
          <p class="mb-0">Degree</p>
          <p v-if="cav.major" class="mb-0" style="color:transparent;">Major</p>
          <p class="mb-0">Date Started</p>
          <p class="mb-0">Date Ended</p>
          <p v-if="cav.student_type === 'GR'" class="mb-0">
            Date of Graduation
          </p>
          <p v-if="cav.student_type === 'GR'" class="mb-0">Special Order No.</p>
          <p v-if="cav.student_type === 'UE'" class="mb-0">Units Earned</p>
          <p class="mb-0">Mode of Study</p>
          <p class="mb-0">Name of Institution</p>
          <p
            v-if="cav.hei_code == '10026'"
            class="mb-0"
            style="color:transparent;"
          >
            Spacer
          </p>
          <!-- spacer for DJEMFCST -->
          <p v-if="cav.former_name" class="mb-0" style="color:transparent;">
            Formerly
          </p>
          <p class="mb-0">Address</p>
        </div>

        <div
          id="separators"
          style="font-family: Arial; font-size: 18px; position:fixed; top: 565px; left: 250px;"
          class=""
        >
          <p class="mb-0">:</p>
          <p class="mb-0">:</p>
          <p v-if="cav.major" class="mb-0" style="color:transparent;">:</p>
          <p class="mb-0">:</p>
          <p class="mb-0">:</p>
          <p v-if="cav.student_type == 'GR'" class="mb-0">:</p>
          <p v-if="cav.student_type == 'GR'" class="mb-0">:</p>
          <p v-if="cav.student_type == 'UE'" class="mb-0">:</p>
          <p class="mb-0">:</p>
          <p class="mb-0">:</p>
          <p
            class="mb-0"
            v-if="cav.hei_code == '10026'"
            style="color:transparent;"
          >
            :
          </p>
          <p
            v-if="cav.former_name != null"
            class="mb-0"
            style="color:transparent;"
          >
            :
          </p>
          <p class="mb-0">:</p>
        </div>

        <div
          id="values"
          style=" font-family: Arial; font-size: 18px; position:fixed; top: 565px; left: 280px;"
          class=""
        >
          <p class="mb-0 text-uppercase">
            <b>{{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}</b>
          </p>
          <p class="mb-0 text-uppercase">
            <b>{{ cav.trimmedProgName }}</b>
          </p>
          <p v-if="cav.major" class="mb-0 text-uppercase">
            <b>{{ cav.major }}</b>
          </p>
          <p class="mb-0 text-uppercase">
            <b>{{ cav.semEnrolled }} AY {{ cav.acadYear }}</b>
          </p>
          <p class="mb-0 text-uppercase">
            <b>{{ cav.semStarted }} AY {{ cav.semYear }}</b>
          </p>
          <p v-if="cav.gradDate" class="mb-0 text-uppercase">
            <b>{{ cav.gradDate }}</b>
          </p>

          <p v-else class="mb-0 text-uppercase">
            <b>{{ cav.units_earned }}</b>
          </p>
          <p v-if="cav.student_type == 'GR'" class="mb-0 text-uppercase">
            <b
              >{{ cav.soLevel }} {{ cav.soRegion }}
              <span v-show="cav.soSeries">NO.</span> {{ cav.soNumber }}
              <span v-show="cav.soSequence">-</span> {{ cav.soSequence }}
              <span v-show="cav.soSeries">S.</span> {{ cav.soSeries }}</b
            >
          </p>

          <p class="mb-0 text-uppercase">
            <b>{{ cav.study_mode }}</b>
          </p>
          <p class="mb-0 text-uppercase">
            <b>{{ cav.name }}</b>
          </p>
          <p v-if="cav.former_name" class="mb-0 text-uppercase">
            <b class="font-italic">(Formerly: {{ cav.former_name }})</b>
          </p>

          <p class="mb-0 text-uppercase">
            <b>{{ cav.schoolAddress }}</b>
          </p>
        </div>
      </div>
      <!-- END - STUDENT INFO -->

      <div
        class="mt-4"
        style="font-family: Arial; font-size: 18px; text-align:justify; text-justify: inter-word;"
      >
        <p style="text-indent: 50px;">
          This is to further certify that the above institution is a duly
          authorized
          <span v-if="cav.type == 'Private HEIs'"
            >private higher education institution</span
          >
          <span v-else>public higher education institution</span> (HEI)
          <span v-if="cav.closed_at !== null"
            >which ceased its operation effective {{ cav.closed_at }} </span
          >and the entries in the Transcript of Records and
          <span v-if="cav.student_type == 'GR'">Diploma</span>
          <span v-else>Certification</span> are authentic and the signatures
          appearing therein are those of the
          <span v-if="cav.type == 'Private HEIs'">HEI President</span
          ><span v-else>LUC President</span> and other authorities.
        </p>
        <p style="text-indent: 50px;">
          This certification must not be honored if the copies of the student's
          Transcript of Records and
          <span v-if="cav.student_type == 'GR'">Diploma</span>
          <span v-else>Certification</span> presented are not duly
          authenticated/certified by the
          <span v-if="cav.type == 'Private HEIs'">HEI</span
          ><span v-else>LUC</span> Registrar.
        </p>
        <p style="text-indent: 50px;">
          Issued upon the request of
          <u
            ><b class="font-italic text-uppercase"
              >{{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}</b
            ></u
          >

          <span
            v-if="cav.purpose == 'Local' && cav.reason != 'Board Examination'"
          >
            for whatever legal purpose it may serve.</span
          >
          <span
            v-if="cav.purpose == 'Local' && cav.reason == 'Board Examination'"
          >
            for board examination purpose.</span
          >

          <span v-if="cav.purpose == 'Abroad'">
            for authentication of the Department of Foreign Affairs (DFA).</span
          >
        </p>
      </div>

      <div class="mt-4">
        <div class="row">
          <div class="col" style="font-family: Arial; font-size: 18px;">
            <p class="mb-5"></p>
            <p class="mb-0" style="font-family: Arial; font-size: 18px;">
              <b>NOT VALID WITHOUT CHED SEAL OR</b>
            </p>
            <p style="text-indent: 15px;">
              <b style="font-family: Arial; font-size: 18px;"
                >WITH ERASURE OR ALTERATION</b
              >
            </p>
          </div>
          <div class="col" style="font-family: Arial; font-size: 18px;">
            <p class="mb-5 ">For the Commission:</p>

            <b
              ><u>{{ cav.trimmedSigned }}</u></b
            >
            <p>{{ cav.position }}</p>
          </div>
        </div>
      </div>

      <div class="mt-5" style="font-family: Arial; font-size: 14px;">
        <div class="d-flex justify-content-center">
          <div class="pr-2">
            <p class="mb-0 ">Application No</p>
            <p class="mb-0 ">Processed by</p>
            <p class="mb-0 ">Reviewed by</p>
            <p class="mb-0 ">CN/OR No.</p>
            <p class="mb-0 ">Date Issued</p>
            <p class="mb-0 ">Amount</p>
          </div>
          <div class="pr-2">
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
            <p class="mb-0">:</p>
          </div>

          <div style="">
            <p class="mb-0">{{ cav.app_id }}</p>
            <p class="mb-0">{{ cav.processed_by }}</p>
            <p class="mb-0">{{ cav.reviewed_by }}</p>
            <p class="mb-0">{{ cav.receipt_no }}</p>
            <p class="mb-0">{{ cav.receipt_date | standardDate }}</p>
            <p class="mb-0">&#8369; 80.00</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintCAV',
  data() {
    return {
      cav: '',
    };
  },
  methods: {
    print(cav) {
      this.cav = cav;
      let position = this.cav.signed_by.split(':');
      let program = this.cav.progName.split('-');
      this.cav.trimmedSigned = position[0];
      this.cav.position = position[1];
      this.cav.trimmedProgName = program[0];
      this.cav.major = program[1];
      setTimeout(() => {
        this.$htmlToPaper('print');
      }, 1000);
    },
  },
};
</script>

<style scoped>
#title {
  font-size: 50px;
}
</style>
